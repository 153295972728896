import React from "react";
import {graphql} from "gatsby";
import { Col } from 'react-grid-system';

import SEO from "../components/Seo";
import Layout from "../layouts";
import Section from "../components/Section";
import {ContentCard} from "../components/Card";
import ContentTabs from '../components/ContentTabs';
import Accordion from '../components/Accordion';

const Page = (props) => {
  const data = props.data.contentfulFaqPage;

  console.log(data);

  return (
    <Layout>
      <SEO title={data.title}/>

      <Section>
        <Col>
          <h1>{data.title}</h1>

          <ContentCard content={data.introduction}/>

          {data.contentTabs && (
            <ContentTabs activeTab={{id: data.contentTabs[0].title}}>
              {data.contentTabs.map(tab => (
                <ContentTabs.Tab key={tab.id} id={tab.title} title={tab.title}>
                  {tab.content && tab.content[0].items ?
                    <Accordion>
                      {tab.content[0].items.map(item => (
                        <Accordion.Item key={item.id} title={item.title}>
                          <div dangerouslySetInnerHTML={{__html: item.content[0].content.childMarkdownRemark.html}}></div>
                        </Accordion.Item>
                      ))}
                    </Accordion>

                    :

                    null

                  }

                </ContentTabs.Tab>
              ))}
            </ContentTabs>
          )}
        </Col>
      </Section>

    </Layout>
  )
};

export default Page;

export const query = graphql`
    query HomeFaqQuery {
        contentfulFaqPage(title: {eq: "FAQ"}) {
            id,
            title,
            introduction {
                childMarkdownRemark {
                    html
                }
            },
            contentTabs {
                id,
                title,
                content {
                    id
                    items {
                        id
                        title,
                        content {
                            ... on Node {
                                __typename
                                ... on ContentfulTextBlock {
                                    id,
                                    content {
                                        childMarkdownRemark {
                                            html
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;
